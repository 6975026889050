/* audio player */
$white: #ffffff;

$smoke-gray: #f6f6f6;
$light-gray: #acacac;

$colors: (
  light: (
    border-color: rgba($light-gray, 0.3),
    highlightColor: #00bbe5,
    primary-text-color: #767f86,
    baseColor: #fff,
  ),
  violet: (
    border-color: $primary,
    highlightColor: $hover,
    primary-text-color: $primary,
    baseColor: #fff,
  ),
);

@function get-color($key, $type: 'light') {
  @each $name, $color in map-get($colors, $type) {
    @if ($key==$name) {
      @return $color;
    }
  }
}

@function get-border($key, $type: 'light') {
  @each $name, $color in map-get($colors, $type) {
    @if ($key==$name) {
      @return $color;
    }
  }
}

@mixin get-color($property, $color, $content: null) {
  #{$property}: get-color($color) $content;
}

@mixin get-border($property, $color, $pixel, $type, $content: null) {
  #{$property}: #{$pixel}px #{$type} get-border($color) $content;

  @at-root .violet & {
    #{$property}: #{$pixel}px #{$type} get-border($color, violet) $content;
  }
}

@mixin boxshadow {
  -webkit-box-shadow: 0px 0px 20px 0px rgba(12, 51, 92, 0.1);
  -moz-box-shadow: 0px 0px 20px 0px rgba(12, 51, 92, 0.1);
  box-shadow: 0px 0px 20px 0px rgba(12, 51, 92, 0.1);
}

$audio_player_base_height: 50px !default;
$audio_player_base_bg_color: #333 !default;
$audio_player_base_text_color: #fff !default;

$secondary-color: #fff !default;
$audio_player_btn_height: $audio_player_base_height !default;
$audio_player_btn_margin: 4px !default;
$audio_player_btn_padding: 11px !default;

$audio_player_controls_margin: 10px !default;

$audio_player_info_font_size: 19.2px !default;
$audio_player_time_font_size: 23px !default;
$audio_player_time_margin: 20px !default;

$audio_player_marquee_margin: $audio_player_controls_margin !default;
$audio_player_marquee_width: calc(100% - 180px) !default;

$audio_player_play_btn_border_color: $secondary-color !default;
$audio_player_play_btn_direction_width: 36% !default;
$audio_player_play_btn_inner_height: 30px !default;
$audio_player_play_btn_inner_width: 30px !default;
$audio_player_play_btn_triangle_color: $audio_player_base_bg_color !default;

.audio_player svg * {
  fill: #181818;

  .violet & {
    fill: $primary;
  }
}

.nostyle {
  padding: 0;
  border: 0;
  background: none;
  outline: none;
}

// common styles for all button/icons
.audio_player .btn {
  display: none;
  width: 45px;
  font-family: Open Sans, sans-serif;
  font-size: 8px;
  outline: none;
  @include get-color(color, primary-text-color);
  text-align: center;

  .button {
    cursor: pointer;
    line-height: 41px;
    //display: inline-block;
    display: none;
  }

  svg {
    vertical-align: middle;
  }

  // .btn:last-of-type {
  //     border-right: none;
  // }

  &:last-of-type {
    border-right: none;
  }

  &.download {
    .spinner {
      line-height: 41px;

      img {
        width: 25px;
        height: 25px;
        vertical-align: middle;
      }
    }
  }
}

@keyframes marquee1 {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes marquee2 {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-200%);
  }
}

@keyframes marquee3 {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-300%);
  }
}

@keyframes marquee4 {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-400%);
  }
}

@keyframes marquee5 {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-500%);
  }
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

.noselect {
  cursor: default;

  /* http://stackoverflow.com/a/4407335/4956731 */
  -webkit-touch-callout: none;

  /* iOS Safari */
  -webkit-user-select: none;

  /* Chrome/Safari/Opera */
  -khtml-user-select: none;

  /* Konqueror */
  -moz-user-select: none;

  /* Firefox */
  -ms-user-select: none;

  /* IE/Edge */
  user-select: none;

  /* non-prefixed version, currently
       not supported by any browser */
}

.hidden {
  display: none !important;
}

.audio_player {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;

  position: relative;

  min-width: 20px;

  .audio_controls {
    position: relative;
    height: 45px;
    width: 45px;

    @include get-border(border, border-color, 2, solid);
    @include get-color(background-color, baseColor);
    border-radius: 100px;

    text-align: center;

    .audio_button {
      cursor: pointer;
      width: 100%;
      height: 42px;
      line-height: 41px;
      outline: none;
      background: transparent;
    }

    .play_pause_button {
      .play_pause_inner {
        position: relative;
      }

      .play_pause_inner {
        .reload-icon {
          display: none;
        }
      }

      .spinner {
        display: none;
        animation: unset;
        width: 42px;
        height: 42px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      &.loading .spinner {
        display: block;
      }

      &.reload {
        .reload-icon {
          display: block;
        }
      }

      .left {
        height: 100%;
        float: left;
        background-color: $secondary-color;
        width: 8px;
        transition: all 0.25s ease;
        overflow: hidden;
      }

      .triangle_1 {
        transform: translate(0, -100%);
      }

      .triangle_2 {
        transform: translate(0, 100%);
      }

      .triangle_1,
      .triangle_2 {
        position: absolute;
        top: 0;
        right: 0;
        background-color: transparent;
        width: 0;
        height: 0;
        border-right: 30px solid white;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        transition: transform 0.25s ease;
      }

      .right {
        height: 100%;
        float: right;
        width: 8px;
        background-color: $secondary-color;
        transition: all 0.25s ease;
      }

      &.paused .left {
        width: 50%;
      }

      &.paused .right {
        width: 50%;
      }

      &.paused .triangle_1 {
        transform: translate(0, -50%);
      }

      &.paused .triangle_2 {
        transform: translate(0, 50%);
      }

      &.reload .ivrplaybtn,
      .ivrplaybtn {
        display: none;
      }

      .ivrpausebtn {
        display: block;
      }

      &.reload .ivrpausebtn {
        display: none;
      }

      &.paused {
        .ivrplaybtn {
          display: inline-block;
          margin-left: 2px;
        }

        .ivrpausebtn {
          display: none;
        }
      }

      &.loading {
        & .ivrplaybtn,
        & .ivrpausebtn {
          display: none;
        }
      }

      .skip_button {
        &.back {
          transform: rotate(180deg);
        }

        .skip_button_inner {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-direction: row;
          flex-direction: row;
        }

        .right_facing_triangle {
          width: 0;
          height: 0;
          border-left: 16px solid $audio_player_play_btn_border_color;
          border-top: 16px solid transparent;
          border-bottom: 16px solid transparent;
        }
      }

      @media screen and (min-width: 370px) {
        .audio_info {
          display: initial;
          animation: marquee5 10s cubic-bezier(0, 0.23, 0.6, -0.09) infinite;
        }
      }

      @media screen and (min-width: 450px) {
        .audio_info {
          animation: marquee4 10s cubic-bezier(0, 0.23, 0.7, -0.09) infinite;
        }
      }

      @media screen and (min-width: 550px) {
        .audio_info {
          animation: marquee3 10s cubic-bezier(0, 0.23, 0.85, -0.09) infinite;
        }
      }

      @media screen and (min-width: 650px) {
        .audio_info {
          animation: marquee2 10s cubic-bezier(0, 0.23, 1, -0.09) infinite;
        }
      }

      @media screen and (min-width: 720px) {
        .audio_info {
          animation: initial;
        }
      }
    }
  }

  &.disabled:after {
    width: 100%;
    height: 100%;
    position: absolute;
    content: '';
    background: rgba(255, 255, 255, 0.5);
    z-index: 111;
    left: 0;
    right: 0;
  }

  > div {
    @include get-border(border-right, border-color, 0, solid);
  }

  .audio_progress_container {
    display: none;
    position: relative;
    height: 8px;

    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    border-radius: 4px;

    margin: 9px 10px 0;

    background-color: #e3e8ec;

    text-align: left;

    .audio_progress {
      position: relative;
      z-index: 1;
      width: 0;
      height: 100%;
      will-change: width;

      background-color: #b2c1c6;

      border-radius: 4px;
      min-width: 10px;

      code {
        position: absolute;
        left: initial;
        right: 0;

        display: block;
        width: 8px;
        height: 8px;
        opacity: 0;
      }
    }

    .audio_progress_overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }

    &.disabled {
      .audio_progress {
        background-color: #eaeaea;
      }
    }
  }

  .audio_info_marquee {
    overflow: hidden;

    /* to make room for .audio_time_progress */
    width: $audio_player_marquee_width;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: $audio_player_marquee_margin;
  }

  .audio_info {
    display: none;
    width: 100%;
    white-space: nowrap;
    word-break: break-all;
    margin: 0;
    font-size: $audio_player_info_font_size;
    color: $audio_player_base_text_color;
  }

  .audio_time_progress {
    align-self: center;
    @include get-color(color, primary-text-color);
    font-size: 12px;
    white-space: nowrap;
    width: 40px;
    padding: 0 5px;
    height: 42px;
    line-height: 43px;
    text-align: center;
    font-family: 'open sans', sans-serif;
  }
}

.small {
  .audio_player {
    .audio_controls {
      height: 24px;
      width: 24px;

      .audio_button {
        height: 20px;
        line-height: 20px;
        margin-top: -1px;

        .ivrplaybtn svg,
        .ivrpausebtn svg {
          width: 10px !important;
          height: 10px !important;
        }
      }

      .play_pause_button.loading {
        .spinner {
          width: 24px;
          height: 24px;
          margin-top: -2px;
          margin-left: -2px;
        }
      }
    }
  }
}

.with-progress {
  .audio_progress_container {
    display: block;
    width: 85px;
  }
}

.volume-wrapper {
  padding: 0 15px;

  .volume-button {
    border: none;
    padding: 4px 6px;

    svg {
      width: 18px;
    }
  }

  input[type='range'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 15px;
    vertical-align: top;
    display: inline-block;
    height: 5px;
    outline: none;
    margin: 0;
    padding: 0;
    transition: all 0.3s ease;
    margin-left: 10px;
    position: relative;
    margin-top: 20px;
    z-index: 2;
    @include get-color(background, highlightColor);

    // Range Handle
    &::-webkit-slider-runnable-track {
      height: 5px;
    }

    &::-moz-range-track {
      // @include get-color(background, highlightColor);
      height: 5px;
    }

    &::-ms-track {
      // @include get-color(background, highlightColor);
      height: 5px;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      @include get-border(border, border-color, 1, solid);
      @include get-color(background, baseColor);
      cursor: pointer;
      position: relative;
      top: -4px;
      transition: background 0.15s ease-in-out;

      &:hover {
        @include get-color(background, baseColor);
      }
    }

    &::-moz-range-thumb {
      -webkit-appearance: none;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      @include get-border(border, border-color, 1, solid);
      @include get-color(background, baseColor);
      cursor: pointer;
      position: relative;
      top: -4px;
      transition: background 0.15s ease-in-out;

      &:hover {
        @include get-color(background, baseColor);
      }
    }

    &::-ms-thumb {
      -webkit-appearance: none;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      @include get-border(border, border-color, 1, solid);
      @include get-color(background, baseColor);
      cursor: pointer;
      position: relative;
      top: -4px;
      transition: background 0.15s ease-in-out;

      &:hover {
        @include get-color(background, baseColor);
      }
    }

    // active-state
    &:active::-webkit-slider-thumb {
      @include get-color(background, baseColor);
    }

    &:active::-moz-range-thumb {
      @include get-color(background, baseColor);
    }

    &:active::-ms-thumb {
      @include get-color(background, baseColor);
    }

    // Focus state
    &:focus::-webkit-slider-thumb {
      box-shadow: none;
      @include get-color(background, baseColor);
    }

    &:focus::-moz-range-thumb {
      box-shadow: none;
      @include get-color(background, baseColor);
    }

    &:focus::-ms-thumb {
      box-shadow: none;
      @include get-color(background, baseColor);
    }
  }
}

/* Dropdown menu */
.audio_player {
  .dropdown-field {
    position: relative;

    &.open {
      > .dropdownmenu {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
      }
    }

    &.player-speed-control {
      .dropdown-button {
        width: 50px;

        .button {
          width: 100%;
          font-size: 14px;
          line-height: 43px;
          cursor: pointer;
          @include get-color(color, primary-text-color);

          span {
            width: 27px;
            display: inline-block;
          }
        }

        .drop-arrow {
          display: inline-block;
          vertical-align: middle;
          width: 0;
          height: 0;
          @include get-border(border-top, primary-text-color, 3, solid);
          border-left: 3px solid transparent;
          border-right: 3px solid transparent;
          border-bottom: 3px solid transparent;
        }
      }
    }

    .dropdownmenu {
      width: 100%;
      position: absolute;
      z-index: 100;
      top: 100%;
      transition: 0.3s ease;
      transform: translateY(-20px);
      opacity: 0;
      visibility: hidden;

      > ul {
        max-height: 240px;
        overflow: auto;
        padding: 10px 0;
        margin: 10px 0;
        border-radius: 4px;
        background-color: $white;

        @include boxshadow;

        li {
          padding: 0;
          font-size: 14px;
          text-align: left;
          cursor: pointer;
          font-weight: 400;

          // transition: 0.1s ease all;
          position: relative;

          a {
            font-size: 14px;
            text-align: left;
            cursor: pointer;
            @include get-color(color, primary-text-color);
            font-weight: 400;
            margin-left: 5px;
            padding: 5px 10px;
            display: block;
            text-decoration: none;
            font-family: Open Sans, sans-serif;
          }

          button {
            padding: 5px 10px;
            @include get-color(color, primary-text-color);
          }

          &:after {
            clear: both;
            display: block;
            content: '';
          }

          code {
            width: 5px;
            height: 95%;
            @include get-color(background-color, highlightColor);
            border-radius: 0;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            margin: 0;
            transition: 0.3s ease;
          }

          &:hover {
            code {
              opacity: 1;
            }
          }
        }
      }

      &.bottom:after,
      &.bottom:before {
        bottom: 100%;
        right: 20px;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        margin-bottom: -10px;
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: $white;
        border-width: 6px;
      }

      &.bottom:before {
        border-color: rgba(217, 219, 219, 0);
        border-bottom-color: rgba(#efefef, 0.6);
        border-width: 7px;
        right: 19px;
      }
    }
  }
}

/* Dropdown menu */
