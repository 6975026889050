// Opacity

.opacity-01 {
  opacity: 0.01 !important;
}

.opacity-02 {
  opacity: 0.02 !important;
}

.opacity-03 {
  opacity: 0.03 !important;
}

.opacity-04 {
  opacity: 0.04 !important;
}

.opacity-05 {
  opacity: 0.05 !important;
}

.opacity-06 {
  opacity: 0.06 !important;
}

.opacity-07 {
  opacity: 0.07 !important;
}

.opacity-08 {
  opacity: 0.08 !important;
}

.opacity-09 {
  opacity: 0.09 !important;
}

.opacity-1 {
  opacity: 0.1 !important;
}

.opacity-15 {
  opacity: 0.15 !important;
}

.opacity-2 {
  opacity: 0.2 !important;
}

.opacity-3 {
  opacity: 0.3 !important;
}

.opacity-4 {
  opacity: 0.4 !important;
}

.opacity-5 {
  opacity: 0.5 !important;
}

.opacity-6 {
  opacity: 0.6 !important;
}

.opacity-7 {
  opacity: 0.7 !important;
}

.opacity-8 {
  opacity: 0.8 !important;
}

.opacity-9 {
  opacity: 0.9 !important;
}

.opacity-10 {
  opacity: 1 !important;
}
