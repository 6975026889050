.spinner-block {
  text-align: center;

  .spinner {
    position: relative;
    display: inline-block;
    width: 64px;
    height: 64px;
    background-image: url(../img/loader.svg);  
    background-repeat: no-repeat;

    animation-name: spin;
    animation-duration: 800ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
  }
}


.spinner-inline {
  height: 16px;
  
  .spinner {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url(../img/loader_small.svg);  
    background-repeat: no-repeat;

    animation-name: spin;
    animation-duration: 800ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
  }
}


@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}