$primary: #FF7900;
$hover:   #F16E00;
$primary-light: #ffd9b7; // основной лайтовый

$text: #000; // основной текст

$color-input-label: #979797; // цвет лейблов у инпутов
$color-light: #e3e8ec;

$orange: #ff4f12;
$orange-hover: #ff612a;
$orange-light: #ff7700;
$pink:   #ffbfc3;
$green:  #32C832;
$red:    #CD3C14;
$yellow: #FFCC00;
$gray:   #999;
$color-muted: #999;
$blue:   #0068B4;

$gray-super-light: #f8f8f8;

$border-color: #DDD;




/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v52/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

// https://habr.com/ru/post/156645/
$mobile: 480px;
$desktop: 1024px;
$animation-duration: 0s;
$md: 767px;
$lg: 991px;

@mixin media($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile) {
      @content;
    }
  } @else if $media == medium {
    @media only screen and (min-width: $mobile + 1) and (max-width: $desktop - 1) {
      @content;
    }
  } @else if $media == desktop_minus {
    @media only screen and (max-width: $desktop) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $md) and (max-width: $desktop) {
      @content;
    }
  } @else if $media == medium_plus {
    @media only screen and (min-width: $mobile + 1) {
      @content;
    }
  } @else if $media == md {
    @media only screen and (min-width: $md + 1) {
      @content;
    }
  } @else if $media == md_minus {
    @media only screen and (max-width: $md) {
      @content;
    }
  } @else if $media == lg {
    @media only screen and (min-width: $lg + 1) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $desktop) {
      @content;
    }
  }
}

@import "../sass/material-components-web.min.scss";
@import "../sass/sidebar.scss";
@import "../sass/forms.scss";
@import "../fonts/fonts.scss";
@import "../sass/spinner.scss";
@import "../sass/empty.scss";
@import "../sass/audio.scss";


.fz12 {font-size: 12px;}
.fz14 {font-size: 14px!important;}
.fz16 {font-size: 16px;}
.fz18 {font-size: 18px!important;}
.fz20 {font-size: 20px!important;}
.fz24 {font-size: 24px;}
.fz26 {font-size: 26px;}
.fz37 {font-size: 37px;}

.fw500 {font-weight: 500;}
.lh14  {line-height: 1.4;}
.lh20  {line-height: 2.0;}

.wsnw {white-space: nowrap;}
.vam {vertical-align: middle!important;}
.vat {vertical-align: top!important;}
.greedy {flex-grow: 2;}

.border-orange {border-color: $orange!important;}

.text-primary {color: $primary;}
.text-color   {color: $text;}
.text-green   {color: $green;}
.text-orange  {color: $orange;}
.text-orange-light  {color: $orange-light;}

.text-muted {
  color: $gray!important;
}

.w-auto {width: auto !important;}
.h-auto {height: auto !important;}

.m-16 {
  margin-left: -16px;
  margin-right: -16px;
}
@include media(md) {
  .m-md-16 {
    margin-left: -16px;
    margin-right: -16px;
  }
}
.ml--12 {
  margin-left: -12px;
}
@include media(mobile) {
  .mt-0-m {
    margin-top: 0 !important;
  }
}
.px-16 {
  padding-left: 16px!important;
  padding-right: 16px!important;
}
@include media(mobile) {
  .px-16-m {
    padding-left: 16px!important;
    padding-right: 16px!important;
  }
}

@include media(mobile) {
  .fz12-m {font-size: 12px;}
  .text-center-m {text-align: center;}
  .overflow-x-scroll-m {overflow-x: scroll!important;}
}
@include media(medium_plus) {
  .text-center-medium_plus {text-align: center;}
  .text-right-medium_plus  {text-align: right;}
  .position-absolute-md {position: absolute;}
}

.top {top: 0;}
.right {right: 0;}

.cursor-pointer {cursor: pointer;}

a {
  color: $primary;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    color: $hover;
  }
}

.btn {
  height: 48px;
  padding: 12px 32px;
  border-width: 2px;
  border-radius: 0;
  font-size: 13px;
  font-weight: 500;
  color: #000;

  .icon {
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    margin-right: 16px;

  }

  &.btn-lg {
    padding: 13px 1rem 12px;
    font-size: 13px;
  }

  &.btn-sm {
    height: 24px;
    padding: 3px 16px;
    border: 1px solid $border-color;
    font-size: 11px;
    color: $primary;

    &:hover {
      border-color: $primary;
    }
  }

  &.btn-orange {
    color: #fff;
    background-color: $orange;
    border-color: $orange;

    &:focus,
    &:hover {
      background-color: $orange-hover;
      border-color: $orange-hover;
    }
  }

  &.btn-primary {
    border-color: $primary;
    background-color: $primary;
  }

  &.btn-secondary {
    border-color: $primary;
    background-color: #fff;
    color: $text;

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled).active {
      border-color: $primary;
      background-color: $primary;
    }
  }
  &.btn-link {
    &.one-line {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &.btn-disabled {
    border-color: #FF7900;
    background-color: #FF7900;
    opacity: 0.5;
  }

  &:hover {
    color: #000;
  }
}

.btn-set {
  .btn {
    width: 48px;
    height: 48px;
    margin-right: 8px;
    margin-bottom: 8px;
    padding: 13px 0;
  }

  .btn.active {
    font-weight: 500;
  }
}

h1,
h2,
h3,
.h1,
.h2,
.h3 {
  color: inherit;
  font-weight: 700;
}

h4,
h5,
h6,
.h4,
.h5,
.h6 {
  color: inherit;
  font-weight: 500;
}

h1,
.h1 {
  font-size: 30px;
  margin-bottom: 32px;

  @include media(mobile) {
    font-size: 20px;
    line-height: 24px !important;
  }
}

h2,
.h2 {
  font-size: 22px;
  margin-bottom: 24px;
}

h3,
.h3 {
  font-size: 15px;
  margin-bottom: 16px;
}

h4,
h5,
.h4,
.h5 {
  font-size: 13px;
  margin-bottom: 12px;
}

p {
  line-height: 1.25;
}

.tooltip {
  .tooltip-inner {
    background-color: #383e4c;
  }

  &.bs-tooltip-auto[x-placement^='top'] .arrow::before,
  &.bs-tooltip-top .arrow::before {
    border-top-color: #383e4c;
  }

  .bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
  .bs-tooltip-bottom .arrow::before {
    border-bottom-color: #383e4c;
  }

  &.show {
    opacity: 1;
  }
}

.rmwc-tooltip {
  &:not(.tooltip-player) {
    max-width: 200px;
  }

  .rmwc-tooltip-inner {
    background-color: #383e4c !important;
    font-size: 13px;
    text-align: center;
  }

  .rmwc-tooltip-arrow {
    border-top-color: #383e4c !important;
  }
}

.w-100-m {
  @include media(mobile) {
    width: 100%!important;
  }
}

.table-block {
  overflow: hidden;

  .table-stats {
    padding: 10px 0;
    font-size: 11px;
    color: $gray;
  }
}

.table {
  color: $text;

  thead tr th,
  .thead .th {
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    font-weight: 500;
    font-size: 11px;
    color: $gray;
  }

  tr, .tr  {
    td, .td {
      padding: 16px 16px;
      border-color: $border-color;
      font-size: 11px;
  
      &.with-icon {
        width: 40px;
        padding-right: 0 !important;
      }
    }

    &:last-child {
      td, .td {
        border-bottom: 1px solid $border-color;
      }
    }

    &.border-bottom-0 {
      td,
      th,
      .td,
      .th {
        border-bottom: none;
      }
    }

    &.border-top-0 {
      td,
      th,
      .td,
      .th {
        border-top: none;
      }
    }

    &.px-0 {
      td:first-child,
      th:first-child,
      .td:first-child,
      .th:first-child {
        padding-left: 0;
      }
      td:last-child,
      th:last-child,
      .td:last-child,
      .th:last-child {
        padding-right: 0;
      }
    }
  }

  &.p24 tr td,
  &.p24 .tr .td {
    @include media(medium_plus) {
      &:first-child {
        padding-left: 24px;
      }
    }
  }

  .icons {
    display: inline-block;
    padding-left: 5px;

    img {
      margin-left: 5px;
    }
  }

  .one-line {
    position: relative;
    max-width: 100%;
    padding-top: 24px;

    .text-overflow {
      position: absolute;
      top: 0;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .table-row-title {
    background-color: $gray-super-light;
    padding: 0 !important;

    td,
    .td {
      position: relative;
      padding: 0 16px;
      vertical-align: middle;
      height: 72px;
      border-bottom: 1px solid $border-color;

      .header {
        display: flex;
        max-width: 400px;

        a.title {
          font-weight: 600;
          font-size: 15px;
        }
      }

      .sub {
        padding-left: 34px;
      }

      @include media(mobile) {
        display: table-cell;
        height: 56px;
      }
    }
  }

  .table-row-highlighted {

    td,
    .td {
      border-bottom: transparent;
      @include media(medium_plus) {
        position: relative;
      }

      .link {
        position: absolute;
        width: 1px;
        height: 100%;
        background-color: $gray;
        top: 0;
        left: 7px;

        &:before {
          content: '';
          position: absolute;
          top: 24px;
          width: 8px;
          height: 1px;
          background-color: $gray;
        }
      }
    }

    &.td-bordered td,
    &.td-bordered .td {
      border-bottom: 1px solid $color-light;

      .link {
        height: 24px;
      }
    }
  }

  .with-checkbox {
    display: flex;

    .mdc-form-field {
      position: relative;
      top: -8px;
      margin-left: -12px;
      margin-right: 10px;
      align-items: start;
    }

    &.align-items-center {
      .mdc-form-field {
        position: static;
      }
    }
  }

  th .with-checkbox,
  .th .with-checkbox {
    align-items: baseline;
    margin-top: -28px;

    .mdc-form-field {
      top: 15px;
    }
  }

  .collapse.show {
    display: table-row-group;
  }

  @include media(mobile) {
    tbody td {
      position: relative;
    }
    tbody td:first-child:before,
    tbody td.last-mobile:before {
      content: '';
      position: absolute;
      bottom: -1px;
      height: 1px;
      width: 16px;
      background-color: #fff;
    }

    tbody td:first-child:before {
      left: 0;
    }
    tbody td.last-mobile:before {
      right: 0;
    }
  }
}

div.table {
  display: table;
  
  .tr {
    display: table-row;

    &.disabled .td > *:not(.opacity1) {
      opacity: 0.5;
    }
  }

  .td,
  .th {
    display: table-cell;
    padding: 16px;
    vertical-align: top;
  }

  .th {
    font-weight: 500;
    vertical-align: bottom;
  }

  &.p16 .td,
  &.p16 .th {
    padding: 16px 0.75rem;
  }

  @include media(mobile) {
    :not(.table-row-title) .td {
      margin-bottom: 10px;
    }
    .p-checkbox {
      padding-left: 30px;
    }
    .row.p-checkbox {
      padding-left: 15px;
    }
  }
}

table {
  &.vat {
    td,
    th {
      vertical-align: top;
    }
  }

  &.p16 {
    td,
    th {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }

  &.p8 {
    td,
    th {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  th {
    font-weight: 500;
  }
}

.marked-list {
  .list-name {
    position: relative;
    width: 100%;
    padding-left: 14px;

    .marked {
      &:before {
        content: '';
        position: absolute;
        top: 6px;
        left: 0;
        display: inline-block;
        width: 6px;
        height: 6px;
        background-color: #fff;
        border-color: $green;
        border-style: solid;
        border-width: 4px;
        border-radius: 4px;
      }

      &.hollow:before {
        border-width: 2px;
        width: 8px;
        height: 8px;
      }

      &.primary:before {
        border-color: $primary;
      }

      &.red:before {
        border-color: $red;
      }

      &.yellow:before {
        border-color: #ffbe08;        
      }

      &.purple:before {
        border-color: #bc104b;
      }

      &.pink:before {
        border-color: #ffbfc3;        
      }

      &.orange:before {
        border-color: #ff4f12;        
      }

      &.d-blue:before {
        border-color: #273a64;        
      }

      &.disabled:before,
      &.gray:before {
        border-color: $gray;
      }
    }

    &.fz11 {
      padding-left: 12px;

      .marked {
        &:before {
          top: 3px;
        }  
      }      
    }
  }

  &.table {
    .list-name {
      width: auto;
      padding-left: 28px;

      span.marked {
        &:before {
          top: 18px;
          left: 16px;
        }
      }
    }
  }
}

span.with-icon,
a.with-icon,
div.with-icon,
button.with-icon {
  img {
    position: relative;
    top: -2px;
    margin-top: 2px;
    margin-right: 5px;
  }

  &.h5,
  &.w24 {
    img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }

  .icon {
    display: inline-block;
    vertical-align: top;
    margin-right: 5px;
    &.icon-delete {
      width: 16px;
      height: 16px;
      background-image: url(../img/delete.svg);
    }
    &.icon-download {
      width: 24px;
      height: 24px;
      background-image: url(../img/download.svg);
    }
    &.icon-edit {
      width: 16px;
      height: 16px;
      background-image: url(../img/edit.svg);
    }
  }

  span.text {
    color: $text;
    line-height: 24px;
  }

  .material-icons {
    position: relative;
    vertical-align: bottom;
  }

  
}

a.with-icon:hover,
span.with-icon:hover {
  text-decoration: none;

  span.text {
    color: $hover;
  }
}


.tag {
  padding: 2px 10px;
  border-radius: 16px;
  border: 1px solid;
  margin-right: 12px;
  line-height: 30px;
  white-space: nowrap;

  &:hover {
    text-decoration: none;
  }

  &.tag-gray {
    background-color: #edeff3;
    border-color: #edeff3;
  }

  &.tag-orange {
    background-color: $orange;
    border-color: $orange;
    color: white;
  }

  &.tag-primary {
    background-color: #fff;
    border-color: $primary;
    color: $primary;

    &.active {
      background-color: $primary;
      color: white;
    }
  }

  &.tag-sm {
    padding: 0 6px;
    margin-right: 8px;
    font-size: 12px;
    line-height: 12px;
  }

  &.with-close {
    position: relative;
    padding-right: 24px;

    .tag-close {
      position: absolute;
      top: 7px;
      right: 7px;
      font-size: 30px;
      line-height: 14px;
    }
  }

  &.with-icon {
    position: relative;
    padding-right: 30px;

    .icon {
      position: absolute;
      top: 3px;
      right: 7px;
      width: 16px;
      height: 16px;
      margin-right: 0;
    }
  }
}

.sfui {font-family: 'SF UI Text Regular';}


body {
  font-family: 'HelveticaNeueCyr';
  font-size: 13px;
  color: $text;
}

#root {
  min-width: 767px;
}

#prewrapper {
  &.fullscreen {
    #sidebar-wrapper,
    header.navbar,
    .fullscreen-hide {
      display: none;
    }

    #page-content-wrapper {
      width: 100%!important;
      margin-left: 0!important;
    }
  }
}

#wrapper {
  display: flex;

  &.no-sidebar {
    
    #page-content-wrapper {
      width: 100%!important;
      margin-left: 0!important;
    }

    .navbar-light {
      .brand-wrap {
        display: flex;
        align-items: center;
        width: 232px;
        height: 64px;
        border-right: 1px solid #333;
        margin-right: 32px;        

        .brand {
          display: inline-block;
          height: 32px;
          width: 140px;
          background-image: url(../img/logo_small.svg);
          background-repeat: no-repeat;

        }
      }
    }
  }
}


.navbar-light {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
  height: 64px;
  flex-wrap: nowrap;
  padding: 0 32px;
  background-color: #000;
  border-left: 1px solid #333;
  color: #fff;

  .brand-wrap {
    display: none;
  }

  .left {
    display: flex;
    align-items: center;

    .server-info {
    }

    .balance-info {
      position: relative;
      white-space: nowrap;
      color: #fff;

      &:hover {
        text-decoration: none;
        color: inherit;
      }

      .badge {
        position: absolute;
        top: -8px;
        right: -16px;
        width: 16px;
        height: 16px;
        padding: 0;
        min-width: auto;
        border-radius: 8px;
        background-color: $yellow;
        background-image: url(../img/lock-white.svg);
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }

  .user-block {
    display: flex;
    align-items: center;

    .user {
      margin-right:  20px;
      margin-left: 15px;

      .user-name {
        font-weight: 500;
      }

      .user-type {
        color: $gray;
      }
    }

    a.logout {
      position: relative;
      display: inline-block;
      width: 16px;
      height: 16px;
      background-image: url(../img/logout.svg);
      background-repeat: no-repeat;

      &:hover {
        background-image: url(../img/logout_active.svg);
      }
    }
  }

  .dropdown-holder {
    display: flex;
    align-items: center;
    position: relative;
    height: 64px;
    padding: 0 56px 0 32px;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      right: 32px;
      width: 8px;
      height: 8px;
      background-image: url(../img/arr_small_down.svg);
      background-repeat: no-repeat;
    }

    .dropdown {
      display: none;
      position: absolute;
      top: 64px;
      right: 0;
      background-color: #000;
      border-top: 1px solid #fff;
      padding: 16px 32px;

      ul.nav {
        display: block;

        li {
          margin-bottom: 16px;

          a {
            color: #fff; 

            &:hover {
              color: $primary;
              text-decoration: none;
            }
          }  

          &:last-child {
            margin-bottom: 0;
          }
        }
        
      }
    }

    &:hover {
      background-color: rgba(24,24,24, .15);      

      .dropdown {
        display: block;
      }
    }
  }
}

main {
  min-height: calc(100vh - 64px);
  padding: 0 32px 32px;
  border-left: 1px solid $border-color;
}

.content {
  padding: 32px 0 24px;

  .title {
    position: relative;

    h1 {
      .status {
        vertical-align: middle;
        margin-left: 12px;
        font-size: 14px;
        font-weight: normal;

        &.online:before {
          content: '';
          position: relative;
          top: 3px;
          width: 16px;
          height: 16px;
          background-color: #57d9b7;
          border-radius: 8px;
          display: inline-block;
          margin-right: 6px;
        }
      }
    }

    .back {
      margin-bottom: 28px;
    }

    .meta {
      /* margin-bottom: 24px; */
    }
  }
}


.card-block {
  height: 100%;
  padding: 20px;
  border: 1px solid $border-color;
  background-color: #fff;

  &.p16 {
    padding: 16px;
  }

  &.gray {
    background-color: $gray-super-light;
    border: none;
  }

  .title {
    margin-bottom: 20px;

    .icon.help {
      vertical-align: top;

      @include media(mobile) {
        display: none;
      }
    }
  }
}

.filter {
  margin-bottom: 32px;  

  .filter-bottom {
    font-size: 11px;

    a, span.link {
      display: inline-block;
      margin-right: 8px;

      &:hover {
        text-decoration: none;
        color: $blue;
      }
    }
  }
}

.filter-tags {
  margin-bottom: 32px;
}

.text-field-settled {
  min-height: 48px;
  flex-direction: row;
  align-items: center;

  label {
    display: block;
    margin-bottom: 0;
    color: $gray;
    font-size: 12px;
  }
}

.icon.help.material-icons {
  position: relative;
  top: 2px;
  color: #b5b5b5;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.icon.help:not(.material-icons),
.icon.calendar:not(.material-icons),
.icon.clock:not(.material-icons) {
  &:not(.mdc-text-field__icon) {
    position: relative;
    top: 2px;
    @include media(mobile) {
      position: absolute;
      right: 16px;
    }
  }
  width: 16px;
  height: 16px;
  display: inline-block;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  cursor: pointer;
  &.help {
    background-image: url(../img/help.svg);
    margin-left: 2px;
  }
  &.calendar {
    background-image: url(../img/calendar.svg);
  }
  &.clock {
    background-image: url(../img/clock.svg);
  }
  &:hover {
    text-decoration: none;
  }
}

h1 .icon {
  top: 0px !important;
}


// MODAL
.modal,
.mdc-dialog {
  .modal-dialog,
  .mdc-dialog__container {
    max-width: 610px;

    &.modal-sm {
      max-width: 320px;
    }
    &.modal-md {
      max-width: 424px;
    }
    &.modal-lg {
      max-width: 800px;
    }
    &.modal-xl {
      max-width: 1000px;
    }

    @include media(mobile) {
      width: auto !important;
    }

    .modal-content,
    .mdc-dialog__surface {
      border-radius: 0;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.56);

      .modal-header {
        position: relative;
        border-bottom: none;
        padding: 8px 52px 8px 16px;

        .modal-paging {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;

          .left,
          .right {
            width: 20%;
          }

          .page {
            font-size: 24px;
            color: #737781;
          }
        }

        .modal-title {
          overflow: hidden;
          padding-right: 16px;
          
          .modal-title-sub {
            font-size: 16px;
            font-weight: normal;
          }
        }

        .back {
          margin-bottom: 4px;
        }
      }

      &.p24 .modal-header .back {
        margin-top: -8px;
      }

      .close {
        position: absolute;
        top: 26px;
        right: 26px;
        width: 24px;
        height: 24px;
        opacity: 1;
        background-image: url(../img/cross.svg);
        background-repeat: no-repeat;
        background-position: center;
        color: white; // temp hack
      }

      @include media(medium_plus) {
        .modal-header.h1-align .close {
          top: 32px;
          right: 32px;
        }

        .modal-header.h4-align .close {
          top: 32px;
        }

        &.p24 .modal-header.h1-align .close {
          top: 40px;
        }
      }

      .modal-body {
        padding: 16px 16px;
        overflow-wrap: break-word;
      }

      .modal-footer {
        justify-content: flex-start;

        .btn {
          margin-right: 16px;
        }

        .p-checkbox {
          padding-left: 40px;
        }

        & > :not(:first-child) {
          margin-left: 0;
        }
      }

      @include media(medium_plus) {
        &.p24 {
          .modal-body {
            padding: 24px;
          }
          .modal-header {
            padding-top: 24px;
            padding-bottom: 24px;
            padding-left: 24px;
          }
        }
      }
    }

    &.modal-banner {
      .modal-content {
        background-image: linear-gradient(117deg, #ffbfc3 62%, #fff 38%);
        padding: 16px 16px 24px;
        border: none;

        .close {
          top: 12px;
          right: 12px;
        }

        .left {
          position: relative;
          display: inline-block;
          width: 64%;
          padding: 2px 0 0;
          border-left: 8px solid #fff;
          font-weight: 600;

          .marked {
            background-color: #fff;
            display: inline;
            padding: 4px 0 3px;

            .indent {
              position: relative;
              left: -4px;

              .heading {
                color: $text;
              }
            }
          }
        }

        .modal-footer {
          border: none;
          padding: 0;
          justify-content: space-between;
          margin-top: 24px;

          .btn-link {
            font-weight: bold;
            color: $primary;
          }
        }
      }
    }
  }
}
.modal-backdrop {
  background-color: #2d2d2d;
}
.menu-toggled .modal-backdrop {
  z-index: 99;
}

.mdc-dialog {
  z-index: 1050;
  max-width: 100%;

  &.modal-sm .mdc-dialog__container {
    max-width: 320px;
  }
  &.modal-md .mdc-dialog__container {
    max-width: 424px;
  }
  &.modal-lg .mdc-dialog__container {
    max-width: 800px;
  }
  &.modal-xl .mdc-dialog__container {
    max-width: 1000px;
  }

  .mdc-dialog__container {
    display: block;
    max-width: 610px;
    margin: 1.75rem auto;

    .mdc-dialog__surface {
      max-width: 100%;
      max-height: none;
      overflow: visible;

      .mdc-dialog__content {
        padding: 1rem;
        overflow: visible;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        letter-spacing: inherit;
        font-weight: inherit;
      }
    }
  }

  &.mdc-dialog--open,
  &.mdc-dialog--opening,
  &.mdc-dialog--closing {
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.items-list {
  padding-left: 0;

  li.with-icon {
    padding-left: 64px;
    min-height: 48px;
    margin-bottom: 20px;
    background-position: left top;
    background-repeat: no-repeat;
    list-style: none;

    &.icon-callback {background-image: url(../img/service/callback.svg);}
    &.icon-voice-record {background-image: url(../img/service/voice_record.svg);}
    &.icon-calltracking {background-image: url(../img/service/calltracking.svg);}
    &.icon-integration {background-image: url(../img/service/integration.svg);}

    .item-name {
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.services-nav {
  .service {
    text-align: center;

    a {
      display: inline-block;
      vertical-align: top;
      background-repeat: no-repeat;
      background-position: center top;
      padding-top: 54px;
      background-size: auto 48px;
    }

    img {
      margin-bottom: 4px;
    }

    .service-name {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 8px;
    }
  }
}


.article {

  .article-body {

    .img {
      background-color: $color-light;  // temp
      height: 150px;
      margin-bottom: 1rem;
    }
  }
}


p.info {
  min-height: 32px;
  background-image: url(../img/info.svg);
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 32px 32px;
  padding-left: 50px;
  margin-bottom: 16px;
  font-size: 12px;

  &.info-orange {
    background-image: url(../img/info_orange_small.svg?v2);
  }

  &.notice {
    background-image: url(../img/info_notice.svg);
  }

  &.check {
    background-image: url(../img/info_check.svg);
  }

  &.info-big {
    min-height: 46px;
    background-size: 46px 46px;
    padding-left: 54px;
  }
}

.play {
  min-height: 48px;
  background-image: url(../img/play_big.svg);
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 48px 48px;
  padding-left: 56px;
}


.list-icons-inline {
  ul {
    padding-left: 0;
    margin-bottom: 0;

    li {
      list-style: none;
      display: inline-block;
      vertical-align: top;
      background-repeat: no-repeat;
      margin-right: 16px;
      margin-bottom: 8px;
      padding-left: 28px;
      background-size: 24px 24px;
      background-position: left top -2px;
      min-height: 24px;

      &.folder {
        padding-left: 24px;
        background-image: url(../img/folder.svg);
        background-size: 16px 16px;
        background-position: left top 1px;
        &.gray {
          background-image: url(../img/folder_gray.svg);
        }
        &.with-delete {
          position: relative;

          .delete-overlay {
            display: none;
            position: absolute;
            left: -2px;
            top: -2px;
            width: calc(100% + 10px);
            height: 24px;
            border-radius: 12px;
            background-image: linear-gradient(to left, #e9eff4 33%, rgba(233, 239, 244, 0.77) 47%);

            a.delete {
              position: absolute;
              top: 4px;
              right: 4px;
              display: inline-block;
              width: 16px;
              height: 16px;
              background-image: url(../img/delete_small.svg);
              background-size: 16px 16px;
            }
          }

          &:hover {
            .delete-overlay {
              display: block;
            }
          }
        }
      }
      &.arrow {
        background-image: url(../img/arrow_right.svg);        
      }
      &.shapes {
        background-image: url(../img/shapes.svg);
      }
      &.delete {
        background-image: url(../img/delete.svg);
      }
      &.support {
        background-image: url(../img/icon_support.svg);
        background-size: 24px 27px;
      }
    }
  }

  &.one-line {
    position: relative;
    max-width: 100%;
    padding-top: 24px;

    ul {
      position: absolute;
      top: 0;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

ul.numbers {
  padding-left: 0;
  list-style: none;

  li {
    display: flex;
    margin-bottom: 20px;

    .number {
      min-width: 50px;
      max-width: 50px;
      height: 50px;
      border: 2px solid #cfd1d4;
      border-radius: 30px;
      padding: 6px;
      margin-right: 15px;

      text-align: center;
      color: #cfd1d4;
      font-size: 24px;
    }

    .content {
      padding-top: 4px;
      line-height: 1.4em;
    }
  }

  &.line {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;

    &:before {
      content: '';
      position: absolute;
      top: calc(50% - 1px);
      width: 100%;
      height: 1px;
      background-color: #cfd1d4;
    }

    li {
      position: relative;
      z-index: 1;
      background-color: #fff;
      margin-bottom: 0;

      .number {
        margin-right: 0;
      }
    }
  }
}

ul.list1 {
  li {
    margin-bottom: 12px;
  }
}

ul.two-columns {
  columns: 2;
  list-style: none;

  &.width-limit {
    li {
      max-width: 200px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.success-block {
  border-top: 1px solid $green;
  border-bottom: 1px solid $green;
  padding: 24px 24px;
  margin-bottom: 30px;

  .title {
    font-size: 16px;
    color: $green;
  }
}


.warning-block {
  border-top: 1px solid $orange;
  border-bottom: 1px solid $orange;
  padding: 24px 24px;
  margin-bottom: 30px;
}


.value-big {
  font-size: 26px;
}

.scroll-268 {
  height: 268px;
  max-height: 268px;
  overflow-y: auto;
  overflow-x: hidden;
}

.scroll-336 {
  height: 336px;
  max-height: 336px;
  overflow-y: auto;
  overflow-x: hidden;
}

.scroll-y {
  overflow-y: auto;
  overflow-x: hidden;
}

// TEMP
.capcha img,
.range img,
.graph img {
  max-width: 100%;
}



.route-block {
  margin-right: 6px;
  margin-bottom: 40px;

  .title {
    background-position: left center;
    background-repeat: no-repeat;
    background-image: url(../img/phone_circle.svg);
    padding-left: 40px;
    margin-bottom: 4px;
    font-size: 24px;
    font-weight: 500;
    line-height: 33px;
  }

  .route-block-body {
    display: inline-block;
    vertical-align: top;
    margin-left: 16px;

    .route-row {
      display: flex;

      .item {
        position: relative;
        min-width: 165px;
        text-align: center;
        padding: 24px 24px 0px;

        .name {
          line-height: 24px;
        }

        .value {
          font-size: 37px;
          font-weight: 300;
        }

        &:first-child {
          border-left: 1px solid $border-color;

          &:before {
            content: '';
            position: absolute;
            top: 30px;
            left: -25px;
            width: 19px;
            height: 11px;
            background-repeat: no-repeat;
            background-size: 19px 11px;
          }

          &.in:before {background-image: url(../img/arr_right.svg);}
          &.out:before {background-image: url(../img/arr_left.svg);}
        }

        @include media(mobile) {
          min-width: auto;
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    }
  }
}

table.modal-list {
  td:first-child,
  td:nth-child(2) {
    padding-right: 0;
  }

  td:nth-child(2) {
    padding-left: 8px;
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;

  .item {
    display: flex;
    padding: 16px 24px;
    border: 1px solid $border-color;
    margin-top: -1px;
    margin-left: -1px;

    .mdc-form-field {
      position: relative;
      top: -8px;
    }

    .after-checkbox {
      padding-left: 4px;
    }

    &.with-checkbox {
      padding-left: 8px;
    }
  }

  @include media(md_minus) {
    grid-template-columns: 1fr;
  }
  @include media(lg) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.stripe-gray {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 16px;
  background-color: #f8f9fa;
  margin-bottom: 24px;

  .left {
    margin-right: 24px;
  }

  .one-line {
    flex-grow: 2;
    position: relative;
    max-width: 100%;

    .text-overflow {
      position: absolute;
      top: -10px;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      .item {
        margin-right: 24px;
      }
    }
  }

  .right {
    margin-left: 24px;
  }
}

.captcha-img-wrapper {
  text-align: center;

  .captcha-img {
    display: inline-block;
    cursor: pointer;
    margin: 5px 0;
  }

  .text {
    font-size: 14px;
    color: $color-muted;
    line-height: 14px;
  }
}

.loading-overlay {
  position:absolute;
  top:0;
  height:100%;
  left:0;
  width:100%;
  background-color:#ffffffba;
  z-index:1000;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor: wait;
}

.copyright {
  text-align: right;
  color: $gray;
  font-size: 12px;
}