.background {
  width: 100vw;
  height: 100vh;
  // background: rgb(27,136,211);
  // background: linear-gradient(180deg, rgba(27,136,211,1) 0%, rgba(4,106,181,1) 100%);
  background-image: url(../img/auth_background.jpg);
  background-position: center center;
  background-size: cover;

  @include media(mobile) {
    height: auto;
    background-image: none;
  }

  .brand-wrap {
    position: absolute;
    padding-top: 64px;
    padding-left: 64px;

    @include media(mobile) {
      position: static;
      padding-top: 16px;
      padding-left: 16px;
    }

    .brand {
      display: inline-block;
      height: 50px;
      width: 146px;
      background-image: url(../img/logo.svg);
      background-repeat: no-repeat;
    }
  }

  .corner {
    position: absolute;

    &.right-bottom {
      bottom: 50px;
      right: 50px;

      @include media(mobile) {
        display: none;
      }
    }

    &.right-top {
      top: 30px;
      right: 50px;

      @include media(mobile) {
        position: static;
        width: 100%;
        height: 30px;
        z-index: 99;
        border-top: 1px solid $border-color;
        padding: 1rem;
      }

      a {
        color: white;
        font-size: 16px;

        @include media(mobile) {
          color: $text;
        }
      }
    }
  }

  .auth-card-wrap {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media(mobile) {
      height: auto;
      align-items: inherit;
      padding-top: 20px;
    }

    .card {
      width: 360px;
      border-radius: 0;
      background-color: #000;

      @include media(mobile) {
        width: 100%;
        box-shadow: none;
        border: none;
      }

      .card-body {
        @include media(wide) {
          padding: 40px;
        }

        .form-group {
          margin-bottom: 2rem;
        }

        h1, h2 {
          color: #fff;
        }

        a {
          font-weight: 700;
          color: #fff;
        }
      }
    }
  }
}
