.rw-popup-container {
  position: absolute;
  z-index: $popup-zindex;
  top: 100%;
  left: -$popup-box-shadow-offset;
  right: -$popup-box-shadow-offset;

  &.rw-dropup {
    top: auto;
    bottom: 100%;
  }

  .rw-state-focus & {
    z-index: $popup-zindex-focused;
  }
}

.rw-popup-transition {
  // the offsets allows for drop shadow to not be clipped by the container
  width: 100%;
  margin-bottom: $popup-box-shadow-offset;
  padding: 0 $popup-box-shadow-offset;

  .rw-dropup > & {
    margin-bottom: 0;
    margin-top: $popup-box-shadow-offset;
  }
}

.rw-popup {
  @include border-top-radius(0);
  @include border-bottom-radius($popup-border-radius);

  box-shadow: $popup-box-shadow;
  border: $popup-border 1px solid;
  background: $popup-bg;

  .rw-dropup & {
    @include border-bottom-radius(0);
    @include border-top-radius($popup-border-radius);

    box-shadow: $popup-box-shadow-up;
  }
}

.rw-popup-transition {
  transition: transform 200ms;
}

.rw-popup-transition-entering {
  overflow: hidden;

  & .rw-popup-transition {
    transform: translateY(0);
    transition-timing-function: ease-out;
  }
}

.rw-popup-transition-exiting {
  & .rw-popup-transition {
    transition-timing-function: ease-in;
  }
}

.rw-popup-transition-exiting,
.rw-popup-transition-exited {
  overflow: hidden;

  & .rw-popup-transition {
    transform: translateY(-100%);
  }

  &.rw-dropup .rw-popup-transition {
    transform: translateY(100%);
  }
}

.rw-popup-transition-exited {
  display: none;
}
