// https://startbootstrap.com/previews/simple-sidebar/
#page-content-wrapper {
  min-width: 100vw;
  min-height: 100vh;

  @include media(desktop) {
    min-width: 0;
    width: calc(100% - 264px);
    margin-left: 264px;
  }

  @include media(desktop_minus) {
    min-width: 0;
    width: calc(100% - 64px);
    margin-left: 64px;
  }
}

#sidebar-wrapper {
  position: fixed;
  top: 0;
  z-index: 101;
  width: 264px;
  height: 100vh;
  max-height: calc(100vh);
  background-color: #fff;
  transition: 0.25s ease-out;

  .brand-wrap {
    position: relative;
    height: 64px;
    padding: 15px 15px 15px 32px;
    background-color: #000;

    .brand {
      display: inline-block;
      height: 32px;
      width: 140px;
      background-image: url(../img/logo_small.svg);
      background-repeat: no-repeat;

      @include media(desktop_minus) {
        width: 32px;
        background-image: url(../img/logo_32.svg);
      }
    }

    @include media(desktop_minus) {
      padding-left: 16px;
    }
  }

  .nav {
    flex-wrap: nowrap;
    padding-top: 7px;
    height: calc(100vh - 64px - 42px);
    overflow-y: auto;
  }

  .sidebar-footer {
    height: 42px;
    padding: 12px 16px 0 40px;
  }

  @include media(desktop_minus) {
    width: 64px;
  } 

}

.nav.sidebar-nav {

  & > li {
    position: relative;
    margin: 0;

    .submenu-toggle {
      position: absolute;
      top: 0;
      right: 0;
      height: 50px;
      width: 40px;
      background-image: url(../img/arr_down.svg);
      background-repeat: no-repeat;
      background-position: center center;
      cursor: pointer;

      &.active {
        background-image: url(../img/arr_up.svg);
      }

      @include media(desktop_minus) {
        display: none;
      }
    }

    & > a {
      display: block;
      padding: 16px 16px 16px 40px;

      font-weight: 500;
      color: $text;

      &:hover,
      &.active {
        text-decoration: none;
        background-color: #DDDDDD;
      }

      .fa {
        width: 16px;
        height: 16px;
        display: inline-block;
        vertical-align: top;
        background-size: 16px 16px;
        margin-right: 20px;
        background-repeat: no-repeat;
        background-position: left;

        @include media(desktop_minus) {
          display: block;
          margin: auto
        }
      }

      .fa-icon-address-book {
        background-image: url(../img/menu/address_book.svg);
      }
      .fa-icon-users {
        background-image: url(../img/menu/users.svg?v2);
      }
      .fa-icon-history {
        background-image: url(../img/menu/history.svg);
      }

      .text {
        @include media(desktop_minus) {
          display: none;
        }
      }

      .sub {
        display: block;
        font-size: 12px;
        font-weight: normal;
      }

      span.text-muted {
        font-weight: normal;
      }

      @include media(desktop_minus) {
        padding: 16px;
      }
    }

    .sub-nav {
      margin-left: 50px;
      padding-left: 0;
      list-style: none;
      border-left: 1px solid $border-color;

      li {
        list-style: none;

        a {
          color: $gray;
          padding: 8px 16px 8px 20px;
          border-left: 4px solid transparent;


          &:hover,
          &.active {
            color: $text;
            border-left-color: #DDD;
          }
        }
      }

      @include media(desktop_minus) {
        display: none;
      }
    }
  }
}

.menu-toggled #sidebar-wrapper {
  left: 0;
}
