::placeholder {
  font-size: 14px;
  color: $color-input-label !important;
}

.form-inline.range {
  flex-flow: row;
}

.mdc-floating-label {
  font-family: 'HelveticaNeueCyr';
  letter-spacing: normal;
}

.w-114 {
  width: 114px;
}
.w-232 {
  width: 232px;
}
.w-290 {
  width: 290px;
  @include media(mobile) {
    width: 100%;
  }
}

// TEXT FIELDS
.mdc-text-field {

  &:not(.mdc-text-field--textarea) {
    height: 48px;
    background-color: #fff;
  }

  &.mdc-text-field--textarea {
    height: 82px;
  }

  &.mdc-text-field--outlined {

    .mdc-text-field__input {
      padding: 20px 16px 4px;
      font-family: 'HelveticaNeueCyr';
      font-size: 14px;
    }

    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-color: $border-color;
      /* background-color: #fff; not working with autocomplete */
      border-radius: 0!important;
      border-width: 2px;
    }

    &.background-white {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        background-color: #fff;
      }
    }
  }

  &.mdc-text-field--disabled {
    .mdc-text-field__input {
      color: $text;
    } 

    .mdc-notched-outline__leading, 
    .mdc-notched-outline__notch, 
    .mdc-notched-outline__trailing {
      background-color: $border-color;
    }
  }

  &.mdc-text-field--fullwidth:not(.mdc-text-field--textarea)
    .mdc-text-field__input {
    padding: 10px 0 0;
  }

  &:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea)
    .mdc-text-field__input {
    border-bottom-color: $color-light;
  }

  &.mdc-text-field--outlined .mdc-floating-label {
    font-size: 13px;
    color: $color-input-label;
  }

  &:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea)
    .mdc-floating-label {
    left: 0;

    &.mdc-floating-label--float-above {
      transform: translateY(-25px) translateZ(0) scale(0.8);
      backface-visibility: hidden;
    }
  }

  .mdc-text-field__ripple {
    display: none;
  }

  .material-icons {
    font-size: 16px;
    color: #b2b2b2 !important;
  }

  .mdc-notched-outline--notched .mdc-notched-outline__notch {
    border-top-style: solid;
    width: 100% !important; // чтобы пофиксить ширину лейбла
  }

  &.with-icon,
  &.mdc-text-field--with-leading-icon.mdc-text-field--outlined {
    .mdc-text-field__icon {
      width: 16px;
      height: 16px;
    }

    .mdc-text-field__icon--trailing {
    }

    &.eye {
      .material-icons {
        background-image: url(../img/icon_eye.svg);
      }

      &.eye-closed {
        .material-icons {
          background-image: url(../img/icon_eye_closed.svg);
        }
      }
    }

    &.help {
      .material-icons {
        background-image: url(../img/help.svg);
      }
    }

    &.search {
      .material-icons {
        background-image: url(../img/search.svg);
      }
    }
  }

  &.mdc-text-field--with-leading-icon.mdc-text-field--outlined {
    .mdc-text-field__icon {
      left: 16px;
    }

    .mdc-floating-label {
      left: 36px;
    }

    .mdc-text-field__input {
      padding-left: 48px;
    }    
  }

  &.mdc-text-field--with-trailing-icon.mdc-text-field--outlined {
    .mdc-text-field__input {
      padding-right: 32px;
    }

    .mdc-text-field__icon {
      right: 8px;
      &:focus {
        outline: none;
      }
    }
  }

  &.mdc-text-field--with-trailing-icon:not(.mdc-text-field--outlined)
    .mdc-text-field__input {
    padding-right: 38px;
  }

  &.mdc-text-field--outlined.mdc-notched-outline--upgraded
    .mdc-floating-label--float-above,
  &.mdc-text-field--outlined
    .mdc-notched-outline--upgraded
    .mdc-floating-label--float-above {
    transform: translateY(-18px) translateX(0) scale(0.8);
  }

  &.mdc-text-field--textarea.mdc-notched-outline--upgraded
    .mdc-floating-label--float-above,
  &.mdc-text-field--textarea
    .mdc-notched-outline--upgraded
    .mdc-floating-label--float-above {
    transform: translateY(-10px) translateX(0) scale(0.8);
  }

  &.mdc-text-field--textarea .mdc-floating-label {
    left: -4px;
    font-size: 14px;
    color: $color-input-label;
  }

  &.mdc-text-field--textarea .mdc-text-field__input {
    margin-top: 32px;
    padding: 0 8px 8px;
    font-size: 14px;
    line-height: 18px;
    resize: none;
  }

  @include media(medium_plus) {
    &.width-80-medium_plus {
      width: 80px;
    }
    &.width-90-medium_plus {
      width: 90px;
    }
    &.width-100-medium_plus {
      width: 100px;
    }
    &.width-110-medium_plus {
      width: 110px;
    }
    &.width-120-medium_plus {
      width: 120px;
    }
    &.width-160-medium_plus {
      width: 160px;
    }
    &.width-320-medium_plus {
      width: 320px;
    }
  }
}

.mdc-text-field--textarea {
  &:not(.mdc-text-field--disabled) {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-color: $border-color;
    }

    &.mdc-text-field--focused {
      border-width: 2px;
    }
  }
}

span.range-dash {
  position: relative;
  width: 16px;

  &:before {
    content: '';
    position: absolute;
    top: -4px;
    width: 16px;
    height: 1px;
    background-color: $border-color;

    @include media(mobile) {
      top: -6px;
      left: -2px;
    }
  }
}

// SELECTS
.mdc-select {
  height: 48px;

  .mdc-select__anchor {
    width: 100%;
    min-width: 160px;
    height: 48px;
  }

  &.w-100 .mdc-select__anchor {
    width: 100%;
  }
  &.w-114 .mdc-select__anchor {
    min-width: 114px;
  }
  &.w-232 .mdc-select__anchor {
    min-width: 232px;
  }
  &.w-290 .mdc-select__anchor {
    min-width: 290px;
    @include media(mobile) {
      min-width: 100%;
    }
  }
  &.w-310 .mdc-select__anchor {
    min-width: 310px;
  }


  @include media(medium_plus) {
    &.width-80-medium_plus {
      width: 80px;
    }
    &.width-90-medium_plus {
      width: 90px;
    }
    &.width-100-medium_plus {
      width: 100px;
    }
    &.width-110-medium_plus {
      width: 110px;
    }
    &.width-120-medium_plus {
      width: 120px;
    }
    &.width-160-medium_plus {
      width: 160px;
    }
    &.width-320-medium_plus {
      width: 320px;
    }
  }

  &.mdc-select--outlined {

    .mdc-notched-outline {
      .mdc-notched-outline__leading, 
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-radius: 0;
        border-width: 2px;
      }
    }

    &:not(.mdc-select--disabled) {
      .mdc-select__anchor {
        background-color: #fff;
      }

      .mdc-notched-outline__leading, 
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-color: $border-color;
      }
    }


    
    .mdc-select__selected-text {
      display: block; // чтобы работал text-overflow: ellipsis
      height: 48px;
      padding: 18px 30px 0 16px;
      overflow: hidden;
      font-size: 13px;
      text-overflow: ellipsis;
    }

    &.with-icon .mdc-select__selected-text {
      padding-right: 60px;
    }

    .mdc-floating-label {
      top: 14px;
      left: 4px;
      padding-right: 10px;
      font-size: 13px;
      color: $color-input-label;
      text-overflow: ellipsis;
    }

    &.mdc-select--focused .mdc-floating-label {
      color: $color-input-label;
    }

    .mdc-select__dropdown-icon {
      right: 4px;
      bottom: 14px;
      background: url(../img/arr_down.svg) no-repeat center;
    }
  }

  &:not(.mdc-select--outlined) {
    .mdc-floating-label {
      top: 14px;
      left: 0;
    }

    .mdc-select__selected-text {
      padding-top: 16px;
      padding-left: 1px;
    }

    .mdc-select__anchor {
      border-bottom: 1px solid $color-light;

      &::before,
      &::after {
        background-color: transparent;
      }
    }

    .mdc-select__dropdown-icon {
      bottom: 12px;
    }
  }


  &.with-icon .icon {
    position: absolute;
    bottom: 17px;
    right: 30px;
  }

  .mdc-notched-outline--notched .mdc-notched-outline__notch {
    width: calc(100% - 42px)!important;
    border-top-width: 1px;
    border-top-style: solid;
  }

  .mdc-select--disabled.mdc-select--outlined .mdc-notched-outline__notch {
    border-color: rgba(0, 0, 0, 0.16);
  }

  &.mdc-select--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    // max-width: calc(100% / 0.75);
    transform: translateY(-8px) scale(0.7);
  }

  .mdc-select__menu .mdc-list {
    .mdc-list-item {
      min-height: 40px;
      height: auto;
      padding: 5px 16px;
      font-size: 13px;

      &.mdc-list-item--selected, 
      &.mdc-list-item--activated {
        color: $text;
      }

      &.mdc-list-item--selected:hover::before {
        opacity: 0;
      }

      &.mdc-list-item--selected::before,
      &.mdc-list-item--selected::after {
        background-color: #9d47ff;
      }
    }

    .mdc-list-item__text {
      font-size: 13px;
    }
  }
}



// CHECKBOXES
.mdc-checkbox {
  .mdc-checkbox__background {
    width: 20px;
    height: 20px;
  }

  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate)
    ~ .mdc-checkbox__background {
    border-color: $border-color;
    background-color: white;
    border-radius: 0;
  }

  .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
  .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
    background-color: #fff;
    border-color: $primary;
  }

  .mdc-checkbox__native-control:enabled
    ~ .mdc-checkbox__background
    .mdc-checkbox__checkmark {
    color: $primary;
    top: 2px;
    left: 2px;
  }

  .mdc-checkbox__checkmark-path {
    stroke-width: 6px;
    transform: scale(0.7);
  }
}

// switches
.mdc-switch {
  &.h17 {
    // расположить элемент ровно на строке
    height: 17px;
  }

  .mdc-switch__thumb {
    width: 18px;
    height: 18px;
    box-shadow: none;
  }
  
  .mdc-switch__track {
    width: 40px;
    height: 24px;
    border-radius: 12px;
    opacity: 1;
    background-color: #fff!important;
    border-color: $border-color!important;
  }

  &:not(.mdc-switch--checked) {
    .mdc-switch__thumb {
      background-color: $gray;
      border-color: $gray;
    } 
  }

  &.mdc-switch--checked {
    .mdc-switch__thumb-underlay {
      transform: translateX(16px);
    } 

    .mdc-switch__thumb {
      background-color: $primary;
      border-color: $primary;
    }

    &.switch-green {
      .mdc-switch__thumb {
        background-color: $green;
        border-color: $green;
      }
    }

    &.switch-orange {
      .mdc-switch__thumb {
        background-color: $orange;
        border-color: $orange;
      } 
    }

    &.switch-red {
      .mdc-switch__thumb {
        background-color: $red;
        border-color: $red;
      }
    }
  }

  .mdc-switch__thumb-underlay {
    top: -12px;
    left: -12px;

    &::before {
      display: none;
    }
  }   
}

.switch-block {
  display: flex;
  align-items: center;

  label {
    margin-right: 8px;
    margin-bottom: 0;
  }
}

.with-switch-cover {
  position: relative;

  .switch-cover {
    position: absolute;
    z-index: 9;
    top: -12px;
    left: -12px;
    width: 64px;
    height: 48px;
    cursor: pointer;
  }
}

.mdc-text-field + .mdc-text-field-helper-line,
.mdc-select + .mdc-select-helper-text {
  padding: 0;
  display: none;
  color: $gray;
  font-family: 'HelveticaNeueCyr';
}

.mdc-text-field.mdc-text-field--invalid + .mdc-text-field-helper-line,
.mdc-text-field.with-helper + .mdc-text-field-helper-line,
.mdc-select.mdc-select--invalid + .mdc-select-helper-text {
  display: block;
}

.mdc-select--invalid + .mdc-select-helper-text--validation-msg {
  color: #ff0000;
}

.mdc-menu-surface {
  box-shadow: 0px 0px 0px -3px rgba(0, 0, 0, 0.1),
    0px 3px 5px 1px rgba(0, 0, 0, 0.1), 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
}

.tab-section {
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-start;
  border-bottom: 1px solid #999;
  overflow-x: hidden;
  min-height: 49px;

  .mdc-tab-bar {
    .mdc-tab {
      padding-left: 0;
      padding-right: 0;
      margin-right: 32px;
      font-family: 'HelveticaNeueCyr';
      text-transform: none;
      letter-spacing: normal;
      font-weight: 700;
      outline: none !important;

      .mdc-tab__text-label {
        color: $text;
        font-size: 14px;
      }

      .mdc-tab__ripple {
        &.mdc-ripple-upgraded--background-focused::before {
          opacity: 0;
        }

        &::before, &::after {
          background-color: $primary;
        }
      }

      &:hover {
        text-decoration: none;
      }
      
      .mdc-tab-indicator .mdc-tab-indicator__content--underline {
        border-top-width: 4px;
        border-color: $primary;
      }

      &.mdc-tab--active .mdc-tab__text-label {
        color: $primary;
      }
    }

    @include media(md) {
      width: auto;
    }
  }

  &.tab-section-top {
    position: relative;
    margin: 0 32px;

    .mdc-tab-bar {
      .mdc-tab {
        font-weight: 500;
      }
    }

    .right {
      position: absolute;
      right: 0;

      a {
        display: inline-block;
        padding: 14px 0;
        color: $gray;
      }

      @include media(desktop_minus) {
        display: none;
      }
    }
  }
}

.tab-section-with-float {
  position: relative;

  .float {
    position: absolute;
    right: 0;
    padding: 0 16px;

    @include media(md_minus) {
      position: static;
    }
  }
}

.mdc-form-field {
  font-family: 'HelveticaNeueCyr';

  label {
    cursor: pointer;
    line-height: 1.2em;
    font-size: 13px;
  }

  &.label-mb-0 {
    label {
      margin-bottom: 0;
    }
  }
  &.label-pt-1 {
    label {
      padding-top: 0.25rem;
    }
  }
  &.label-bold {
    label {
      font-weight: 500;
    }
  }
}

// radios
.mdc-radio {
  &.mdc-ripple-upgraded--background-focused .mdc-radio__ripple::before,
  &:not(.mdc-ripple-upgraded):focus .mdc-radio__ripple::before {
    opacity: 0;
  } 

  .mdc-radio__native-control:enabled
  + .mdc-radio__background .mdc-radio__outer-circle {
    border-color: $border-color!important;
  }
}

.radios {
  .mdc-form-field-container {
    margin-left: -8px;
    margin-bottom: 16px;

    .mdc-text-field-helper-line {
      margin-top: -12px;
      padding-left: 44px;
    }
  }

  .link {
    position: relative;
    top: 1px;
  }

  .checkbox-block {
    padding: 2px 0 0 32px;
  }
}

.mdc-form-field-container.disabled {
  opacity: 0.3;
}

.text-field-container {
  position: relative;

  .sub {
    position: absolute;
    top: 48px;
    font-size: 11px;
  }

  .mdc-text-field-helper-line.position-absolute {
    bottom: -20px;
  }

  &.with-trailing-icon {
    .mdc-text-field__input {
      padding-right: 30px !important;
    }
    .icon {
      position: absolute !important;
      top: 15px !important;
      right: 8px;
    }
  }
}

.form-control {
  height: 48px;
  border-color: $border-color;
  border-width: 2px;
  border-radius: 0;

  &:hover,
  &:focus {
    border-color: $primary;
    box-shadow: none;
  }

  &.width-66 {
    width: 66px;
  }
  &.width-44 {
    width: 44px;
  }



  @include media(medium_plus) {
    &.width-120-medium_plus {
      width: 120px;
    }
    &.width-160-medium_plus {
      width: 160px;
    }
  }
}

@include media(medium_plus) {
  .width-120-medium_plus {
    .form-control {
      width: 120px;
    }
  }
  .width-160-medium_plus {
    .form-control {
      width: 160px;
    }
  }
}


.form-control-sm {
  width: 64px;
  height: 32px;

  &.error {
    border-color: #ff0000;
  }
}

input[type=number].form-control-sm {
  width: 48px;
}

input[type='color'].form-control {
  width: 50px;
  padding: 5px 8px;
}

.react-datepicker-wrapper {
  z-index: 1;

  .react-datepicker__input-container {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 16px;
      left: 16px;
      display: inline-block;
      width: 16px;
      height: 16px;
      background-image: url(../img/calendar.svg);
      background-size: 16px 16px;
      background-repeat: no-repeat;
    }

    .form-control {
      padding-left: 48px;
      font-size: 13px;
      background: none; // фикс, чтобы было видно иконку z-index-1
    }
  }
}
.datepicker-clock
  .react-datepicker-wrapper
  .react-datepicker__input-container:before {
  background-image: url(../img/clock.svg);
}

.css-1pcexqc-container {
  .css-bgvzuu-indicatorSeparator {
    display: none;
  }

  .css-bg1rzq-control, 
  .css-yoq6hv-control,
  .css-1tmv9x5-control {
    min-height: 48px;
  }

  .css-go9pih-option, .css-fk865s-option, .css-1r08ony-option {
    height: 40px;
    align-items: center;
    display: flex;
  }
}