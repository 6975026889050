// User Pages

.app-logo {
  height: $logo-height;
  width: $logo-width;
  background: url('../utils/images/logo-inverse.png');
}

.app-logo-inverse {
  height: $logo-height;
  width: $logo-width;
  background: url('../utils/images/logo.png');
}

.app-login-box {
  .app-logo {
    margin-bottom: $layout-spacer-lg;
  }

  h4 {
    margin-bottom: $layout-spacer-x;
    font-weight: normal;

    div {
      opacity: 0.6;
    }

    span {
      font-size: $font-size-lg;
    }
  }
}

.card-icon {
  float: left;
  color: #545cd8;
  font-size: 28px;
  margin-right: 10px;
}

.user-settings-card {
  .info-text {
    font-size: 14px;
    line-height: 1.17;
    color: rgba(16, 24, 40, 0.6);

    .subtitle {
      font-weight: 700;
      margin-bottom: 5px;
    }
  }

  .form-control {
    font-size: 14px;
    white-space: pre-wrap;
  }
}

.user-settings-main,
.modal-form {
  white-space: nowrap;
  min-width: 400px;

  label {
    font-size: 12px;
    margin-bottom: 0;
  }
  input {
    font-size: 14px;
  }
  .text-label {
    line-height: 1.5;
    padding: 0.4rem 0;
    height: calc(1.5em + 0.75rem + 2px);
  }

  .form-text {
    white-space: normal;
  }
}

.modal-info {
  font-size: 12px;
  white-space: normal;
}

.call-graph {
  overflow-x: scroll;
}
